function fetch(opts) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method, opts.url);
    xhr.onload = (event) => {
      if (event.target.status >= 200 && event.target.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: event.target.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = (event) => {
      reject({
        status: event.target.status,
        statusText: xhr.statusText
      });
    };
    if (opts.headers) {
      Object.keys(opts.headers).forEach(key => xhr.setRequestHeader(key, opts.headers[key]));
    }

    if (opts.responseType) {
      xhr.responseType = opts.responseType;
    }
    let params = opts.params;
    // We'll need to stringify if we've been given an object
    // If we have a string, this is skipped.
    if (params && typeof params === 'object') {
      params = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    }
    xhr.send(params);
  });
}

export default fetch;
