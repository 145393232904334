
// convert radians to degrees
function radToDeg(rad) {
  return rad * 360 / (Math.PI * 2);
}

// convert degrees to radians
function degToRad(deg) {
  return deg * Math.PI * 2 / 360;
}

// modulo for negative values
function mod(n) {
  return ((n % (2 * Math.PI)) + (2 * Math.PI)) % (2 * Math.PI);
}

function parseHTML(html) {
  return new DOMParser().parseFromString(html, 'text/html').body.firstChild
}

export {radToDeg, degToRad, mod, parseHTML};