import {parseHTML} from './utils';
import {error, info, warning} from './icons';

const TEMPLATE = `
<div class="notification">
    <div class="notification__content">
        <span class="icon icon--notification"></span>
        <span class="notification__text"></span>
    </div>
</div>
`;

class Notification {

  constructor() {
    this.errIcon = document.createElement('span');
    this.errIcon.innerHTML = error;
  }

  notify(message, type, dismiss) {
    let element = parseHTML(TEMPLATE);
    element.classList.add(`notification--${type}`);
    element.querySelector('.notification__text').textContent = message;
    document.body.appendChild(element);
    switch (type) {
      case 'error':
        element.querySelector('.icon--notification').innerHTML = error;
        break;
      case 'warning':
        element.querySelector('.icon--notification').innerHTML = warning;
        break;
      case 'info':
        element.querySelector('.icon--notification').innerHTML = info;
        break;
    }

    if (dismiss && parseInt(dismiss) > 0) {
      setTimeout(() => {
        document.body.removeChild(element);
        element = null;
      }, dismiss);
    }
  }
}

export {Notification}