import add_src from 'material-design-icons/content/svg/production/ic_add_24px.svg';
import remove_src from 'material-design-icons/content/svg/production/ic_remove_24px.svg';

import marker_src from 'material-design-icons/toggle/svg/production/ic_radio_button_checked_24px.svg';
import marker_heading_src from 'material-design-icons/maps/svg/production/ic_navigation_24px.svg';

import gps_on_src from 'material-design-icons/device/svg/production/ic_gps_fixed_24px.svg';
import gps_off_src from 'material-design-icons/device/svg/production/ic_location_disabled_24px.svg';

import place_src from 'material-design-icons/maps/svg/production/ic_place_24px.svg';
import attachment_src from 'material-design-icons/file/svg/production/ic_attachment_24px.svg';
import description_src from 'material-design-icons/action/svg/production/ic_description_24px.svg';

import verleend_src from 'material-design-icons/action/svg/production/ic_thumb_up_24px.svg';
import gereed_src from 'material-design-icons/action/svg/production/ic_check_circle_24px.svg';
import bouw_src from 'material-design-icons/action/svg/production/ic_build_48px.svg';
import status_src from 'material-design-icons/action/svg/production/ic_turned_in_24px.svg';


import error_src from 'material-design-icons/alert/svg/production/ic_error_24px.svg';
import warning_src from 'material-design-icons/alert/svg/production/ic_warning_24px.svg';
import info_src from 'material-design-icons/action/svg/production/ic_info_48px.svg';

import layer_src from 'material-design-icons/maps/svg/production/ic_layers_48px.svg';

import close_src
  from 'material-design-icons/navigation/svg/production/ic_close_18px.svg';

const close = `
<svg>
  <use xlink:href="${close_src}" />
</svg>`;


const layer = `<svg><use xlink:href="${layer_src}" /></svg>`;
const bouw = `<svg><use xlink:href="${bouw_src}" /></svg>`;
const place = `<svg><use xlink:href="${place_src}" /></svg>`;
const attachment = `<svg><use xlink:href="${attachment_src}" /></svg>`;
const gereed = `<svg><use xlink:href="${gereed_src}" /></svg>`;
const status = `<svg><use xlink:href="${status_src}" /></svg>`;
const description = `<svg><use xlink:href="${description_src}" /></svg>`;
const verleend = `<svg><use xlink:href="${verleend_src}" /></svg>`;
const add = `
<svg>
  <use xlink:href="${add_src}" />
</svg>`;
const remove = `
<svg>
  <use xlink:href="${remove_src}" />
</svg>`;


const marker = `
<svg>
  <use xlink:href="${marker_src}" />
</svg>`;

const marker_heading = `
<svg>
  <use xlink:href="${marker_heading_src}" />
</svg>`;

const gps_on = `
<svg>
  <use xlink:href="${gps_on_src}" />
</svg>`;

const gps_off = `
<svg>
  <use xlink:href="${gps_off_src}" />
</svg>`;

const error = `
<svg>
  <use xlink:href="${error_src}" />
</svg>`;

const info = `
<svg>
  <use xlink:href="${info_src}" />
</svg>`;

const warning = `
<svg>
  <use xlink:href="${warning_src}" />
</svg>`;

export {
  add,
  attachment,
  bouw,
  close,
  description,
  error,
  gereed,
  gps_off,
  gps_on,
  info,
  layer,
  marker,
  marker_heading,
  place,
  remove,
  status,
  verleend,
  warning
}
