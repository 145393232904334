import vikfetch from './fetch';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {bbox} from 'ol/loadingstrategy';
import {Fill, Text, Stroke, Style} from 'ol/style';
import {parseHTML} from './utils';
import Modal from './modal';
import {
  attachment,
  bouw,
  description, gereed,
  place,
  status as status_icon, verleend
} from './icons';
import {get as getProjection} from 'ol/proj';



let vergunningVectorSource;
const TPL = `<article class="vergunning">
    <h3 class="vergunning__title"></h3>
    <ul class="list list--vergunning-details">
        <li class="list__item list__item--text"><span class="icon">${description}</span> <span class="text"></span></li>
        <li class="list__item list__item--locatie"><span class="icon">${place}</span></span> <span class="text"></span></li>
        <li class="list__item list__item--status"><span class="icon"></span> Status: <b></b></li>
        <li class="list__item list__item--documenten"><span class="icon">${attachment}</span> Documenten: 
            <div class="document-container is-loading"></div> </li>
    </ul>
</article>`;

class Vergunningen {

  constructor(dispatcher) {
    this.dispatcher = dispatcher;
    this.modal = new Modal();


    this.dispatcher.dispatchEvent('map-add-layer', new VectorLayer({
      source: this.getVergunningVectorSource(),
      projection: getProjection('EPSG:28992'),
      extent: [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999],
      style: this.getVergunningenLayerVectorStyle,
      updateWhileAnimating: true
    }));

    this.dispatcher.addEventListener('map-single-click', this.onSingleClick.bind(this));
  }

  getVergunningVectorSource() {
    if (!vergunningVectorSource) {
      vergunningVectorSource = new VectorSource({
        format: new GeoJSON({}),
        url: (extent) => {

          return `${process.env.WFS_URL}${[
            'version=1.1.0',
            'request=GetFeature',
            'typename=topp%3AO10006_swf_gisvg_vergunning',
            'outputFormat=application/json',
            'srsname=EPSG:28992',
            `bbox=${extent.join(',')},EPSG:28992`
          ].join('&')}`
        },
        strategy: bbox
      })
    }

    return vergunningVectorSource;
  }

  getVergunningenLayerVectorStyle(feature) {
    let fill = new Fill({color: 'rgba(255, 255, 255, .20)'});
    let status = feature.get('status');

    switch (feature.get('status')) {
      case 'Verleend':
        fill = new Fill({color: 'rgba(0, 255, 0, .60)'});
        break;
      case 'Bouw start':
        fill = new Fill({color: 'rgba(0, 176, 240, .60)'});
        break;
      case 'S4W. Toestemming':
        fill = new Fill({color: 'rgba(255, 255, 0, .60)'});
        break;
      case 'S4W Start':
        fill = new Fill({color: 'rgba(0, 176, 240, .60)'});
        break;
      case 'S4W Gereedmelding':
        fill = new Fill({color: 'rgba(128, 128, 128, .60)'});
        // EW: 2016-07-18
        status = '';
        break;
      case 'S5D. Toestemming':
        // fill = new ol.style.Fill({color: 'rgba(255, 255, 0, .60)'});
        // EW: 2016-07-18
        status = '';
        break;
      case 'S5M. Toestemming':
        // fill = new ol.style.Fill({color: 'rgba(255, 255, 0, .60)'});
        // EW: 2016-07-18
        status = '';
        break;
      case '':
      case 'null':
        // standaard kleur
        break;
      default:
        console.info('unknown status', feature.get('status'));
    }

    if (status === 'null') {
      status = '';
    }

    return [
      new Style({
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 1.0)',
          width: .75
        }),
        fill: fill,
        text: new Text({
          text: status,
          scale: 1,
          fill: new Fill({color: '#FFFFFF'}),
          stroke: new Stroke({color: '#000000', width: 2.5})
        }),
      })
    ];
  }

  onSingleClick(event) {
    let features = [];
    event.data.map.forEachFeatureAtPixel(event.data.pixel, feature => {
      features.push(feature)
    });
    if (features.length === 0) {
      return;
    }

    const title = `Informatie vergunning(en)`;
    let div = document.createElement('div');

    features.forEach(feature => {
      const content = parseHTML(TPL);
      content.querySelector('.vergunning__title').textContent = feature.get('vergunning');
      content.querySelector('.list__item--text .text').textContent = feature.get('tekst');
      content.querySelector('.list__item--locatie .text').textContent = feature.get('lokatie');
      content.querySelector('.list__item--status .icon').innerHTML = this.getStatusIcon(feature.get('status'));
      content.querySelector('.list__item--status b').textContent = feature.get('status');
      content.querySelector('.document-container').setAttribute('id', `documenten-${feature.get('vergunning')}`);

      let documenturl = `${process.env.WFS_URL}${[
        'version=1.1.0',
        'request=GetFeature',
        'typename=topp%3AO10006_swf_gisvg_document_nw1',
        'srsname=EPSG%3A3857',
        'outputFormat=application%2Fjson',
        `cql_filter=id%3D${feature.get('id')}`
      ].join('&')}`;

      vikfetch({
        method: 'get',
        url: documenturl,
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(data => {
        const docs = document.createElement('ul');
        docs.classList.add('list--vergunningen__documenten');
        // console.log(typeof data, data, data.features);
        if (typeof data === 'string') {
          // IE 11 bug
          data = JSON.parse(data);
        }
        data.features
          .sort((a, b) => a.properties.titel.toString().localeCompare(b.properties.titel.toString()))
          .forEach(doc => {
            const li = document.createElement('li');
            const atag = document.createElement('a');
            li.classList.add('list__item');
            li.appendChild(atag);
            atag.setAttribute('href', `https://docs.sudwestfryslan.nl/${doc.properties.url}`);
            atag.setAttribute('target', '_blank');
            atag.textContent = doc.properties.titel;
            docs.appendChild(li);
          });
        content.querySelector('.document-container').appendChild(docs);
        content.querySelector('.document-container').classList.remove('is-loading');
      });

      div.appendChild(content)
    });

    this.modal.open(div, title);
    div = null;
  }

  getStatusIcon(status) {
    let statusIcon = status_icon;

    switch (status) {
      case 'Bouw start':
      case 'S4W Start':
        statusIcon = bouw;
        break;
      case 'S4W Gereedmelding':
        statusIcon = gereed;
        break;
      case 'Verleend':
      case 'S4W. Toestemming':
      case 'S5D. Toestemming':
      case 'S5M. Toestemming':
        statusIcon = verleend;
        break;
      case '':
      case 'null':
        // standaard kleur
        break;
      default:
        console.info('unknown status', feature.get('status'));
    }

    return statusIcon;
  }
}

export {Vergunningen}
