import 'ol/ol.css';
import {radToDeg} from './utils';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile'
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import proj4 from 'proj4'
import {get as getProjection} from 'ol/proj'
import {TileWMS} from 'ol/source';
import OSM from 'ol/source/OSM';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import {transform} from 'ol/proj';
import {DragPan, PinchZoom, PinchRotate, MouseWheelZoom} from 'ol/interaction';
import {add, marker_heading, remove} from './icons';
import vikfetch from './fetch';
import Projection from 'ol/proj/Projection';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {getTopLeft, getWidth} from 'ol/extent';
import {register} from 'ol/proj/proj4';

let map, view;

const MAX_ZOOM = 24;
const MIN_ZOOM = 0;


class VikMap {

  constructor(el, dispatcher) {
    this.dispatcher = dispatcher;
    this.zoom = 19;
    this.heading = document.getElementById('heading_marker');
    this.heading.innerHTML = marker_heading;
    this.activeLayer = 1;

    this._create(el);
    this._addListeners();
    this._addButtons();

  }

  _create(el) {
    if (!map) {

      const CENTER = transform([5.530126, 53.060924], 'EPSG:4326', 'EPSG:3857');
      view = new View({
        center: CENTER,
        zoom: this.zoom,
      });

      // creating the map
      map = new Map({
        layers: this.layers,
        loadTilesWhileInteracting: true,
        loadTilesWhileAnimating: true,
        target: el,
        interactions: [
          new DragPan(),
          new PinchZoom(),
          new PinchRotate(),
          new MouseWheelZoom()
        ],
        controls: [],
        // view: this.getView()
      });

      this._createLayers();
    }
  }

  _addListeners() {
    map.on('moveend', event => {
      if (this.zoom !== event.map.getView().getZoom()) {
        this.zoom = event.map.getView().getZoom();
      }
      this.dispatcher.dispatchEvent('map-moveend', event);
    });

    map.on('pointerdrag', event => {
      this.dispatcher.dispatchEvent('map-pointerdrag', event);
      this.dispatcher.dispatchEvent('map-update-heading');
    });
    map.on('postcompose', event => {
      this.dispatcher.dispatchEvent('map-postcompose', event);
    });
    map.on('singleclick', event => {
      this.dispatcher.dispatchEvent('map-single-click', event);
    });

    this.dispatcher.addEventListener('map-update-heading', this.updateHeading.bind(this));
    this.dispatcher.addEventListener('map-animate-view', (event) => {
      // map.getView()
      map.getView().animate({
        rotation: -event.data[2],
        // center: coordinate,
        center: this._getCenterWithHeading(event.data, -event.data[2], view.getResolution()),
        duration: 25
      });
    });
    this.dispatcher.addEventListener('map-add-layer', (event) => {
      map.addLayer(event.data);
    });
    this.dispatcher.addEventListener('map-add-overlay', (event) => {
      map.addOverlay(event.data);
    })
  }

  _addButtons() {

    const layerRadios = document.querySelectorAll('.input__control--radio');
    for (let i = 0; i < layerRadios.length; i++) {
      layerRadios.item(i).addEventListener('change', e => {
        this.layers[this.activeLayer].setVisible(false);
        this.activeLayer = i;
        this.layers[this.activeLayer].setVisible(true);
      })
    }

    this.zoomInBtn = document.getElementById('zoom_in_btn');
    this.zoomOutBtn = document.getElementById('zoom_out_btn');
    this.zoomInBtn.querySelector('.icon').innerHTML = add;
    this.zoomOutBtn.querySelector('.icon').innerHTML = remove;
    this.zoomInBtn.addEventListener('click', () => this.setZoom(1));
    this.zoomOutBtn.addEventListener('click', () => this.setZoom(-1));
  }

  _createLayers() {

    // Create the aerial layer, disabled by default
    // const aerial = new TileLayer({
    //   source: new TileWMS({
    //     url: process.env.WMS_URL,
    //     params: {'LAYERS': process.env.WMS_LUFO, 'TILED': true},
    //     serverType: 'geoserver'
    //   }), title: `Luchtfoto's`, type: 'base', visible: false
    // });

    // Create osm layer (default)
    const osmLayer = new TileLayer({
      source: new OSM(
      ), title: 'OSM', visible: false, type: 'base'
    });
    this.layers = [
      osmLayer,
      // aerial
    ];
    this._addBRTMap();

    const layers = map.getLayers();
    this.layers
      .reverse()
      .forEach((layer,idx) => layers.insertAt(idx, layer));
    this.layers.reverse();

  }

  _addBRTMap() {
    proj4.defs("EPSG:28992","+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");
    register(proj4);

    const projectionExtent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];
    const projection = getProjection('EPSG:28992');
    projection.setExtent(projectionExtent);
    const resolutions = [3440.640, 1720.320, 860.160, 430.080, 215.040, 107.520, 53.760, 26.880, 13.440, 6.720, 3.360, 1.680, 0.840, 0.420, 0.210];

    const matrixIds = new Array(15);
    for (var z = 0; z < 15; ++z) {
      matrixIds[z] = 'EPSG:28992:' + z;
    }

    const options = {
      url: 'https://geodata.nationaalgeoregister.nl/tiles/service/wmts?',
      layer: 'brtachtergrondkaart',
      matrixSet: 'EPSG:28992',
      format: 'image/png',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds
      }),
      style: 'default',
      wrapX: false
    };


    const brt = new TileLayer({
      source: new WMTS(options),
      title: `BRT`, type: 'base', visible: true
    });
    this.layers.push(brt);

    map.setView(new View({
      minZoom: 0,
      maxZoom: 15,
      projection: projection,
      center: [172500, 560200],
      zoom: 10
    }));
  }

  /**
   * recenters the view by putting the given coordinates
   * at 3/4 from the top or the screen
   * @param position
   * @param rotation
   * @param resolution
   * @returns {*[]}
   */
  _getCenterWithHeading(position, rotation, resolution) {
    const size = map.getSize();
    const height = size[1];

    return [
      position[0] - Math.sin(rotation) * height * resolution * 1 / 4,
      position[1] + Math.cos(rotation) * height * resolution * 1 / 4
    ];
  }

  /**
   *
   * @returns {ol.Map}
   */
  getMap() {

    return map;
  }

  /**
   * Controls the zoom using the buttons
   * @param dir
   */
  setZoom(dir) {

    if (map) {
      this.zoom = Math.min(dir + this.zoom, MAX_ZOOM);
      map.getView().animate({
        zoom: this.zoom,
        duration: 50
      });
    }
  }

  getView() {
    return view;
  }

  /**
   * Updates the heading indicator
   */
  updateHeading() {
    this.heading.style.transform = `rotate(${radToDeg(view.getRotation())}deg)`;
  }
}

export {VikMap};
