import {parseHTML} from './utils';
import {close} from './icons';

let layer, backdrop;

class Modal {
  constructor() {
    this._check();
  }

  open(content, title) {
    backdrop.classList.add('modal-backdrop--active');
    this.modal = parseHTML(`
<section class="modal">
    <div class="modal__title"><h2></h2><button class="modal__close-button"><span class="icon"></span></button> </div>
    <div class="modal__content"></div>
</section>
`);
    this.modal.querySelector('.modal__close-button .icon').innerHTML = close;
    this.modal.querySelector('.modal__title h2').innerText = title;
    this.modal.querySelector('.modal__content').appendChild(content);
    this.closeButton = this.modal.querySelector('.modal__close-button');
    this.closeButton.addEventListener('click', this);

    layer.appendChild(this.modal);

  }

  handleEvent(event) {
    if (event.type === 'click') {
      if (event.currentTarget === this.closeButton || event.currentTarget === backdrop) {
        this.close();
      }
    }
  }

  close() {
    if (this.modal) {
      this.modal.classList.remove('modal--open');
      this.modal.parentElement.removeChild(this.modal);
      this.closeButton.removeEventListener('click', this);
      this.closeButton = null;
      this.modal = null;
      backdrop.classList.remove('modal-backdrop--active');
    }
  }

  /**
   * @private
   */
  _check() {
    if (!backdrop) {
      backdrop = document.createElement('div');
      backdrop.classList.add('modal-backdrop');

      backdrop.addEventListener('click', this);

      layer = document.createElement('div');
      layer.classList.add('modal-container');
      layer.addEventListener('click', e => e.stopImmediatePropagation());

      document.body.appendChild(backdrop);
      backdrop.appendChild(layer);
    }
  }
}

export default Modal