import "@babel/polyfill";
import {Vergunningen} from './vergunningen';
import {GPS} from './gps';
import {VikMap} from './vikmap';
import EventDispatcher from './event-dispatcher';

(function() {
  const dispatcher = new EventDispatcher()

  window.addEventListener('load', () => {
    let map = new VikMap(document.getElementById('map'), dispatcher);
    let vergunningen = new Vergunningen(dispatcher);
    let gps = new GPS(dispatcher);
  })
}());
